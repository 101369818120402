import { useState } from "react";
import FileSaver, { saveAs } from "file-saver";
import "./styles.css";

var points = [];
var largestRadius = 0;

export default function App() {
  const [isTable, changeTable] = useState(true);
  const [updateCounter, forceUpdate] = useState(0);
  function buttonClick() {
    if (isTable) {
      changeTable(false);
    } else {
      changeTable(true);
    }
  }
  function solveEquation() {
    points = [];
    let sheetThickness = document.getElementById("sheetThick").value; //use same unit
    let totalXYHeight = document.getElementById("height").value;
    let desiredHeight = document.getElementById("dHeight").value;
    let stepSize = totalXYHeight / (desiredHeight / sheetThickness);
    let xScale = desiredHeight / totalXYHeight;

    let firstBounds = [4.8, 0]; //top, bottom
    for (let i = firstBounds[0]; i > firstBounds[1]; i -= stepSize) {
      let rad = (Math.pow((i - 5) / -2, 0.5) - 0.1) * xScale;
      points[points.length] = rad;
      if (rad > largestRadius) {
        largestRadius = rad;
      }
    }

    let secondBounds = [0, -15]; //top, bottom
    for (let i = secondBounds[0]; i > secondBounds[1]; i -= stepSize) {
      let rad = ((-1 / 100) * Math.pow(i + 7, 2) + 1.97113883) * xScale;
      points[points.length] = rad;
      if (rad > largestRadius) {
        largestRadius = rad;
      }
    }
    forceUpdate(updateCounter + 1);
    /*for (let i = 0; i < points.length; i++) {
      console.log(i + "," + points[i]);
    }*/
  }
  return (
    <div className="App">
      <h1>Equation Slice Generator</h1>
      <h2>Desired height and sheet thickness should use the same units</h2>
      {/*<h2>
        All equations must be in x equals form and square roots to the power of
        1/2
      </h2>
      <p>
        This means you need to solve for x with a y equals equation i.e. y=x^2
        would be x=sqrt(y)
      </p>*/}
      <span>Total X or Y Length </span>
      <input
        type="text"
        defaultValue="19.8"
        id="height"
        style={{ width: "4rem" }}
      />
      <span> Sheet Thickness </span>
      <input
        type="text"
        defaultValue="2"
        id="sheetThick"
        style={{ width: "4rem" }}
      />
      <span> Desired Height </span>
      <input
        type="text"
        defaultValue="300"
        id="dHeight"
        style={{ width: "4rem" }}
      />
      <button onClick={solveEquation}>Solve</button>
      <button onClick={buttonClick}>Toggle CSV</button>
      <button onClick={exportSVG}>Export</button>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Radius in input units</th>
          </tr>
        </thead>
        <tbody>
          {points.map((p, n) => {
            if (isTable) {
              return (
                <tr key={n}>
                  <td>{n}</td>
                  <td>{p}</td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="32"
                    >
                      <path
                        d={
                          "M 10 30 A " +
                          p +
                          " " +
                          p +
                          " 0 0 1 " +
                          (p * 2 + 10) +
                          " 30 L 10 30"
                        }
                        stroke="black"
                        fill="transparent"
                        strokeWidth="2"
                      />
                    </svg>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={n}>
                  <td>{n + "," + p}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
  /*<form onSubmit={formSubmit}>
        <EquationInput num="1" />
        <EquationInput num="2" />
        <input type="submit" />
      </form> */
}

/*function EquationInput(props) {
  return (
    <>
      <p>Equation {props.num}</p>
      <input type="text" id={"equation" + props.num} />
      <p>Upper Y bound</p>
      <input type="text" id={"upper" + props.num} />
      <p>Lower Y bound</p>
      <input type="text" id={"lower" + props.num} />
    </>
  );
}*/

//solveEquation(`x=(y-5/-2)^(1/2)+.1`);
//solveEquation();

function exportSVG() {
  var xPos = 0;
  var yPos = 0;
  var scaleFactor = 72 / 25.4; // 72 dpi divided by 25.4 mm per inch
  var tileSize = (largestRadius + 2) * scaleFactor;
  var tileCount = Math.ceil(Math.sqrt(points.length));

  var svgArray = [];
  var svgSize = tileCount * (tileSize * 2);
  let index = 0;

  var header = `
  <!-- Generator: Equation Slice Generator - Andy Levine)  -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 ${svgSize} ${svgSize}" style="enable-background:new 0 0 ${svgSize} ${svgSize};" xml:space="preserve">\n`;
  var footer = `</svg>`;

  for (let i = 0; i < tileCount; i++) {
    for (let j = 0; j < tileCount; j++) {
      if (index < points.length) {
        svgArray[index] = `<path d="M ${xPos} ${yPos} A ${
          points[index] * scaleFactor
        } ${points[index] * scaleFactor} 0 0 0 ${
          xPos + points[index] * 2 * scaleFactor
        } ${yPos} L ${xPos} ${yPos}" stroke="black" fill="none" stroke-width="2"/> \n`;
        xPos += tileSize * 2;
        index++;
      }
    }
    xPos = 0;
    yPos += tileSize;
  }

  var linearArray = "";
  svgArray.forEach((text) => {
    linearArray += text;
    return;
  });

  var file = new File([header, linearArray, footer], "test.svg", {
    type: "text/plain;charset=utf-8"
  });
  console.log(linearArray);
  saveAs(file);
}
